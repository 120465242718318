import { computed, unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import {
  GetBillingOrgByOrgId,
  useGetBillingOrgByOrgIdQuery,
} from '../queries/billing.queries';

export function useDeviceLimit(orgId: MaybeRef<number>) {
  const { data: organization } = useGetBillingOrgByOrgIdQuery(
    computed(() => new GetBillingOrgByOrgId({ orgId: unref(orgId) })),
  );

  const orgParentName = computed(() => organization.value?.parent?.name);
  const atParentDeviceLimit = computed(
    () =>
      !!(organization.value?.parent && organization.value.is_at_parent_limit),
  );
  const orgDeviceLimit = computed(() => organization.value?.device_limit);
  const totalDeviceCount = computed(() => {
    return organization.value?.device_count_with_children
      ? organization.value.device_count_with_children
      : organization.value?.device_count;
  });
  const orgAccessKey = computed(() => organization.value?.access_key);

  return {
    orgParentName,
    atParentDeviceLimit,
    orgDeviceLimit,
    totalDeviceCount,
    orgAccessKey,
  };
}
