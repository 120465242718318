var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ax-devices",attrs:{"fluid":""}},[_c('ax-zone-breadcrumbs'),_c('div',[_c('v-card-text',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center mb-4"},[_c('h1',{staticClass:"tw-py-0"},[_vm._v("Devices")]),_c('div',[(_vm.integrationAgentDeployFlag)?_c('ax-menu',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var value = ref.value;
return [_c('ax-button',_vm._g({attrs:{"mode":"secondary","data-test-id":"devices-page-add-devices-button"}},on),[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.mdiPlusCircleOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('devices.addMenu.addDevices:button'))+" "),_c('v-icon',{class:{ rotate: value },attrs:{"dense":"","right":""}},[_vm._v(" "+_vm._s(_vm.mdiMenuDown)+" ")])],1)]}}],null,false,962860608)},[_c('v-list',{staticClass:"add-devices-menu"},[_c('v-list-item',{attrs:{"two-line":""},on:{"click":_vm.modals.add.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.addMenu.manualImport:title'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('devices.addMenu.manualImport:description'))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""},on:{"click":_vm.modals.thirdPartyAdd.open}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.addMenu.thirdPartyImport:title'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('devices.addMenu.thirdPartyImport:description'))+" ")])],1)],1)],1)],1):_c('ax-button',{attrs:{"data-test-id":"devices-page-add-devices-button","mode":"secondary"},on:{"click":_vm.modals.add.open}},[_vm._v(" Add Devices ")]),_c('ax-button',{staticClass:"tw-ml-4",attrs:{"data-test-id":"devices-page-create-group-button","mode":"secondary","to":{
              name: 'createGroup',
              query: { o: _vm.orgIdRef, frompage: '/devices' },
            }}},[_vm._v(" Create Group ")]),_c('ax-button',{staticClass:"ml-4",attrs:{"mode":"secondary","disabled":_vm.hasNoDevices,"href":_vm.hasNoDevices ? '' : _vm.exportCsvUrl}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.mdiExportVariant))]),_vm._v(" "+_vm._s(_vm.$t('general.buttons.exportCSV'))+" ")],1)],1)]),_c('ax-sidebar-layout',{attrs:{"value":_vm.queryState.filter_panel_open},on:{"input":function (value) { return _vm.updateQueryState(
              { filter_panel_open: value },
              { bypassUiUrlUpdate: true, bypassApiUrlUpdate: true }
            ); }},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('div',{staticClass:"tw-h-8 tw-text-center"},[_c('ax-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.canResetFilters),expression:"canResetFilters"}],attrs:{"small":"","mode":"secondary"},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t('general.buttons.resetFilters')))])],1),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.filterPanels),callback:function ($$v) {_vm.filterPanels=$$v},expression:"filterPanels"}},[_c('ax-accordion-filter',{attrs:{"data-test-id":"device-status-module","title":"Device Status","clearable":_vm.canResetStatusFilters},on:{"clear":_vm.resetStatusFilters}},[_c('ax-radio-group',{attrs:{"value":_vm.queryState.connected},on:{"change":function (v) { return _vm.updateQueryState({ connected: v }); }}},[_c('ax-radio',{attrs:{"data-test-id":"device-status-connected","value":1,"label":"Connected"}}),_c('ax-radio',{attrs:{"data-test-id":"device-status-disconnected","value":0,"label":"Disconnected"}})],1),_c('v-divider',{staticClass:"tw-my-2"}),_c('ax-radio-group',{attrs:{"value":_vm.complianceRadioValue},on:{"change":_vm.handleComplianceRadioSelection}},[_c('ax-radio',{attrs:{"data-test-id":"device-status-up-to-date","value":"up_to_date","label":"Up To Date"}}),_c('ax-radio',{attrs:{"data-test-id":"device-status-compliant","value":"compliant","label":"Compliant"}}),_c('ax-radio',{attrs:{"data-test-id":"device-status-failed","value":"failed","label":"Failed Update Attempts"}}),_c('ax-radio',{attrs:{"data-test-id":"device-status-scheduled","value":"scheduled","label":"Scheduled"}})],1),_c('v-divider',{staticClass:"tw-my-2"}),_c('ax-checkbox',{staticClass:"tw-ml-0",attrs:{"data-test-id":"device-status-needs-attention","hide-details":"","input-value":_vm.queryState.needs_attention,"label":"Needs Attention"},on:{"input":function (needs_attention) { return _vm.updateQueryState(
                      { needs_attention: needs_attention },
                      { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                    ); }}}),_c('ax-checkbox',{staticClass:"tw-ml-0",attrs:{"data-test-id":"device-status-not-compatible","hide-details":"","input-value":_vm.queryState.is_compatible,"label":"Not Compatible"},on:{"input":function (is_compatible) { return _vm.updateQueryState(
                      { is_compatible: is_compatible },
                      { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                    ); }}}),_c('ax-checkbox',{staticClass:"tw-ml-0",attrs:{"data-test-id":"device-status-recently-added","hide-details":"","input-value":_vm.queryState.recently_added,"label":"Recently Added (Last 5 Days)"},on:{"input":function (recently_added) { return _vm.updateQueryState(
                      { recently_added: recently_added },
                      { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                    ); }}}),_c('ax-checkbox',{staticClass:"tw-ml-0",attrs:{"data-test-id":"device-status-excluded-from-reports","hide-details":"","input-value":_vm.queryState.excluded_from_reports,"label":"Excluded From Reports"},on:{"input":_vm.handleExcludedFromReportsCheckboxUpdate}})],1),_c('ax-accordion-filter',{attrs:{"title":"Device Info","data-test-id":"device-sidebar-device-info","number-of-active-filters":_vm.queryState.device_name.length +
                _vm.queryState.tag.length +
                _vm.queryState.last_logged_in_user.length +
                _vm.queryState.ip_address.length +
                _vm.queryState.serial_number.length}},[_c('ax-combobox',{attrs:{"id":"axDevicesDeviceNameFilter","value":_vm.queryState.device_name,"chips":"","label":"Host Name","multiple":"","clearable":"","append-icon":null,"error-messages":_vm.deviceNameFilterError},on:{"change":_vm.handleDeviceNameFilterInput}}),_c('ax-combobox',{attrs:{"id":"axDevicesTagsFilter","value":_vm.queryState.tag,"chips":"","label":"Tags","multiple":"","clearable":"","append-icon":null},on:{"change":function (tag) { return _vm.updateQueryState({ tag: tag }); }}}),_c('ax-combobox',{attrs:{"id":"axDevicesLastLoggedInUserFilter","value":_vm.queryState.last_logged_in_user,"chips":"","label":"Last Logged In User","multiple":"","clearable":"","append-icon":null},on:{"change":function (last_logged_in_user) { return _vm.updateQueryState({ last_logged_in_user: last_logged_in_user }); }}}),_c('ax-combobox',{attrs:{"id":"axDevicesIpAddressFilter","data-test-id":"ip-filter-input","value":_vm.queryState.ip_address,"chips":"","label":"IP Address","multiple":"","clearable":"","append-icon":null,"error-messages":_vm.ipAddressFilterError},on:{"change":_vm.handleIpAddressFilterInput}}),_c('ax-combobox',{attrs:{"id":"axDevicesSerialNumberFilter","value":_vm.queryState.serial_number,"chips":"","label":"Serial Number","multiple":"","clearable":"","append-icon":null,"error-messages":_vm.serialNumberFilterError},on:{"change":_vm.handleSerialNumberFilterInput}})],1),_c('ax-accordion-filter',{attrs:{"title":"OS","data-test-id":"device-sidebar-os","number-of-active-filters":_vm.queryState.os.length,"filter-max-length":_vm.totalOses}},[_c('ax-os-filters',{attrs:{"oses":_vm.oses,"value":_vm.queryState.os},on:{"input":function (v) { return _vm.updateQueryState(
                      { os: v },
                      { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                    ); }}})],1),_c('ax-accordion-filter',{attrs:{"title":"Policies","data-test-id":"sidebar-policies-dropdown-button","number-of-active-filters":_vm.queryState.policy.length}},[_c('ax-combobox',{attrs:{"value":_vm.selectedPolicies,"items":_vm.policyComboboxItems,"label":"Policies","data-test-id":"sidebar-policy-searchbar","chips":"","multiple":"","clearable":"","append-icon":null},on:{"change":_vm.handlePolicyFilterSelection}})],1),_c('ax-accordion-filter',{attrs:{"title":"Groups","number-of-active-filters":_vm.queryState.group.length}},[_c('ax-combobox',{attrs:{"value":_vm.selectedDeviceGroups,"items":_vm.deviceGroupComboboxItems,"label":"Groups","chips":"","multiple":"","clearable":"","append-icon":null},on:{"change":_vm.handleGroupFilterSelection}})],1),_c('ax-accordion-filter',{attrs:{"title":"Severity","data-test-id":"device-sidebar-severity","info-text":"Find devices with uninstalled patches with these severities.","number-of-active-filters":_vm.queryState.severity.length,"filter-max-length":_vm.severityFilters.length}},_vm._l((_vm.severityFilters),function(filter,index){return _c('ax-checkbox',{key:index,attrs:{"label":filter.label,"value":filter.value,"hide-details":"","input-value":_vm.queryState.severity},on:{"input":function (v) { return _vm.updateQueryState(
                      { severity: v },
                      { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                    ); }}})}),1),_c('ax-accordion-filter',{attrs:{"title":"Vulnerability or CVE ID","info-text":"Find devices with uninstalled patches with at least one of these ids.\n              Matches only for CVE, ADV, CESA, CEBA, RHSA","number-of-active-filters":_vm.queryState.vulnerability.length}},[_c('ax-combobox',{attrs:{"value":_vm.queryState.vulnerability,"label":"Search CVE (Min. 6 Chars.)","chips":"","multiple":"","clearable":"","append-icon":null,"error-messages":_vm.vulnerabilityFilterError},on:{"change":_vm.handleVulnerabilityFilter}})],1)],1)]},proxy:true}])},[[(_vm.bannerProps)?_c('ax-status-banner',{attrs:{"subject":_vm.bannerProps.subject,"status":_vm.bannerProps.status,"description":_vm.bannerProps.description,"secondary":_vm.bannerProps.secondary},on:{"dismissed":function($event){_vm.executionId = undefined}}}):_vm._e(),(_vm.devices && _vm.deviceGroupsById)?_c('ax-devices-table',{attrs:{"headers":_vm.zoneDevicesTableHeaders,"active-headers":_vm.activeHeaders,"devices":_vm.devices,"total-devices":_vm.totalDevices,"table-state":_vm.tableState,"query-state":_vm.queryState,"item-key":"id","data-test-id":"devices-page-table","no-data-text":"No devices","show-select":""},on:{"update:tableState":function($event){_vm.tableState=$event},"update:table-state":function($event){_vm.tableState=$event},"query-state-updated":_vm.updateQueryState,"tag-clicked":_vm.addTagToFilter,"columns-updated":function (cols) { return _vm.updateQueryState(
                  { columns: cols.map(function (ref) {
                          var value = ref.value;

                          return value;
                        }) },
                  { bypassApiUrlUpdate: true }
                ); }},scopedSlots:_vm._u([{key:"bulk-actions-menu",fn:function(){return [_c('div',{staticClass:"tw-ml-4 tw-mr-6"},[_c('ax-menu',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var value = ref.value;
return [_c('ax-button',_vm._g({attrs:{"mode":"secondary"}},on),[_c('div',[_vm._v("Actions ("+_vm._s(_vm.selectedDevices.length)+")")]),_c('v-icon',{class:{ rotate: value },attrs:{"dense":"","right":""}},[_vm._v(_vm._s(_vm.mdiMenuDown))])],1)]}}],null,false,2959741790)},[_c('v-list',{attrs:{"disabled":_vm.selectedDevices.length === 0,"dense":""}},[_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.modals.assignToGroup.open}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.assignToGroup'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.modals.applyTags.open}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.applyTags'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.modals.removeTags.open}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.removeTags'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":function($event){return _vm.scanDevices(_vm.selectedDevices)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.scan'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.exportToCSV}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.export'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.modals.remove.open}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.remove'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.modals.reboot.open}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.reboot'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('ax-table-row-actions-column',[_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.modals.assignToGroup.open)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.assignToGroup'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.modals.applyTags.open)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.applyTags'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.modals.removeTags.open)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.removeTags'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.runDeviceAction(item, function () { return _vm.scanDevices(_vm.selectedDevices); })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.scan'))+" ")])],1),(
                    _vm.canRemoteControl(
                      item.os_family,
                      _vm.remoteControlFlag,
                      _vm.canControlDevice,
                      _vm.hasBillingPlanWithRemoteControl
                    )
                  )?_c('v-list-item',{attrs:{"disabled":!_vm.isRemoteControlReady(
                      item.connected,
                      item.status.device_status
                    )},on:{"click":function($event){_vm.runDeviceAction(item, function () { return _vm.openRemoteControlWindow(item); })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.remoteControl'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.exportToCSV)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.export'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.modals.remove.open)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.remove'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.runDeviceAction(item, _vm.modals.reboot.open)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('devices.actions.reboot'))+" ")])],1)],1)]}},{key:"item.is_compatible",fn:function(ref){
                    var item = ref.item;
return [_c('v-lazy',[_c('div',[(item.is_compatible === false)?_c('ax-menu',{staticClass:"tw-p-2",attrs:{"open-on-hover":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{staticClass:"tw-mr-1",attrs:{"color":"error","x-small":""}},[_vm._v(" "+_vm._s(_vm.mdiCircle)+" ")]),_vm._v("Not Compatible ")],1)]}}],null,true)},[_c('v-list',{staticClass:"tw-px-3",attrs:{"dense":""}},_vm._l((Object.entries(
                          item.compatibility_checks
                        ).reduce(
                          function (acc, ref) {
                                  var k = ref[0];
                                  var v = ref[1];

                                  return (v ? acc.concat( [k]) : acc);
                    },
                          []
                        )),function(value,index){return _c('div',{key:index,staticClass:"tw-text-sm"},[_vm._v(" "+_vm._s(_vm._f("snakeToReadable")(value))+" ")])}),0)],1):_vm._e(),(item.needs_reboot)?_c('div',{staticClass:"tw-mt-1"},[_c('v-icon',{staticClass:"tw-mr-1",attrs:{"color":"error","x-small":""}},[_vm._v(" "+_vm._s(_vm.mdiCircle)+" ")]),_vm._v("Needs Reboot ")],1):_vm._e()],1)])]}},{key:"item.server_group_id",fn:function(ref){
                        var item = ref.item;
return [_c('v-lazy',[_c('ax-device-group-column',{attrs:{"group":_vm.deviceGroupsById[item.server_group_id],"organization-id":item.organization_id}})],1)]}},{key:"item.patches",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(_vm._s(item.patches))])]}},{key:"item.pending_patches",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(" "+_vm._s(item.pending_patches)+" ")])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('v-lazy',[_c('ax-device-status-column',{attrs:{"device":item}})],1)]}}],null,false,1261569860),model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}}):_vm._e()]],2)],1)],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.assignToGroup.opened),callback:function ($$v) {_vm.$set(_vm.modals.assignToGroup, "opened", $$v)},expression:"modals.assignToGroup.opened"}},[_c('ax-devices-assign-to-group-modal',{attrs:{"devices":_vm.selectedDevices,"groups":_vm.deviceGroupsSelect,"default-group":_vm.defaultGroup,"policies":_vm.policies},on:{"close-modal":_vm.modals.assignToGroup.close,"assign":function (ref) {
          var selectedGroup = ref.selectedGroup;

          _vm.assignDevicesToGroup(_vm.selectedDevices, selectedGroup);
          _vm.modals.assignToGroup.close();
        }}})],1),_c('ax-modal',{model:{value:(_vm.modals.applyTags.opened),callback:function ($$v) {_vm.$set(_vm.modals.applyTags, "opened", $$v)},expression:"modals.applyTags.opened"}},[_c('ax-devices-apply-tags-modal',{on:{"close-modal":_vm.modals.applyTags.close,"apply":_vm.applyTagsToDevices}})],1),_c('ax-modal',{model:{value:(_vm.modals.removeTags.opened),callback:function ($$v) {_vm.$set(_vm.modals.removeTags, "opened", $$v)},expression:"modals.removeTags.opened"}},[_c('ax-devices-remove-tags-modal',{attrs:{"selected-devices":_vm.selectedDevices},on:{"close-modal":_vm.modals.removeTags.close,"remove":_vm.removeTagsFromDevices}})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.reboot.opened),callback:function ($$v) {_vm.$set(_vm.modals.reboot, "opened", $$v)},expression:"modals.reboot.opened"}},[_c('ax-devices-reboot-modal',{attrs:{"devices":_vm.selectedDevices},on:{"close-modal":_vm.modals.reboot.close,"reboot":function () {
          _vm.rebootDevices(_vm.selectedDevices);
          _vm.modals.reboot.close();
        }}})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.remove.opened),callback:function ($$v) {_vm.$set(_vm.modals.remove, "opened", $$v)},expression:"modals.remove.opened"}},[_c('ax-devices-remove-modal',{attrs:{"devices":_vm.selectedDevices},on:{"close-modal":_vm.modals.remove.close,"remove":function () {
          _vm.removeDevices(_vm.selectedDevices);
          _vm.modals.remove.close();
        }}})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.add.opened),callback:function ($$v) {_vm.$set(_vm.modals.add, "opened", $$v)},expression:"modals.add.opened"}},[_c('ax-add-devices-modal',{on:{"close-modal":_vm.modals.add.close}})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.thirdPartyAdd.opened),callback:function ($$v) {_vm.$set(_vm.modals.thirdPartyAdd, "opened", $$v)},expression:"modals.thirdPartyAdd.opened"}},[_c('ax-action-modal',{attrs:{"title":"integrations.agentDeploy.title","title-with-steps":"integrations.agentDeploy.titleWithSteps","submit-button":"integrations.agentDeploy.deploy:button","action-type":_vm.actionType},on:{"close-modal":_vm.modals.thirdPartyAdd.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }